// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advices-tsx": () => import("./../../../src/pages/advices.tsx" /* webpackChunkName: "component---src-pages-advices-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-guide-tsx": () => import("./../../../src/pages/guide.tsx" /* webpackChunkName: "component---src-pages-guide-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-map-tsx": () => import("./../../../src/pages/map.tsx" /* webpackChunkName: "component---src-pages-map-tsx" */),
  "component---src-pages-organisations-tsx": () => import("./../../../src/pages/organisations.tsx" /* webpackChunkName: "component---src-pages-organisations-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-varvara-tsx": () => import("./../../../src/pages/varvara.tsx" /* webpackChunkName: "component---src-pages-varvara-tsx" */),
  "component---src-pages-velingrad-tsx": () => import("./../../../src/pages/velingrad.tsx" /* webpackChunkName: "component---src-pages-velingrad-tsx" */)
}

